import apiClient from '../middleware/apiClient';
import { ACCOUNT_COLLECTIONS_2, CONSENT, CONVOY_INDICATORS } from '../helpers/apis';
import { flagEnabled } from '../helpers/featureFlags';
import { NO_XFINITY_SERVICES, NO_NOW_SERVICES } from '../helpers/routes';
import { hasEverHadXfinityServices, hasEverHadNowServices } from '../helpers/account';
import { isNowPage } from '../helpers/now';

// Fetch Account
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';

// Indicators
export const GET_CONVOY_INDICATORS = 'GET_CONVOY_INDICATORS';
export const GET_CONVOY_INDICATORS_SUCCESS = 'GET_CONVOY_INDICATORS_SUCCESS';

function dispatchGetAccount({ crsId, consentId }) {
  const consentEnabled = flagEnabled('consent.enabled');
  const endpoint = ACCOUNT_COLLECTIONS_2;
  if (crsId && consentEnabled) {
    // Handles ?crsId=..., a.k.a. new user consent flow
    return {
      type: GET_ACCOUNT,
      payload: apiClient.fetch(CONSENT.GET_BILL_INFO).then((response) => {
        const {
          address,
          city,
          state,
          zipCode,
        } = response.serviceAddress;
        return {
          ...response,
          serviceAddress: {
            addressLine1: address,
            addressLine2: null,
            city,
            state,
            zip: zipCode,
            zip4: null,
          },
        };
      }),
    };
  }
  return {
    type: GET_ACCOUNT,
    payload: (async () => {
      const accountPromise = apiClient.fetch(endpoint);
      if (consentId && consentEnabled) {
        // Handles ?consentId=..., a.k.a. existing user consent flow
        const [consentInfo, account] = await Promise.all([
          apiClient.fetch(`${CONSENT.GET_BILL_INFO}?consentId=${encodeURIComponent(consentId)}`),
          accountPromise,
        ]);
        return {
          ...account,
          affiliate: consentInfo.affiliate,
        };
      }
      return accountPromise;
    })(),
  };
}

function dispatchGetConvoyIndicators(accNum) {
  return {
    type: GET_CONVOY_INDICATORS,
    payload: apiClient.fetch(CONVOY_INDICATORS(accNum)),
  };
}

function dispatchGetConvoyIndicatorsSuccess(indicators) {
  return {
    type: 'GET_CONVOY_INDICATORS_SUCCESS',
    payload: indicators,
  };
}

function dispatchGetAccountSuccess(account) {
  return {
    type: GET_ACCOUNT_SUCCESS,
    payload: account,
  };
}

function dispatchGetAccountFailure(error) {
  return {
    type: GET_ACCOUNT_FAILURE,
    payload: error,
  };
}

export const getAccount = () => async (dispatch, getState) => {
  const {
    account: { cached } = {},
    auth: { crsId, consentId } = {},
  } = getState();
  if (cached) {
    return null;
  }

  try {
    const response = await dispatch(dispatchGetAccount({ crsId, consentId })).payload;

    if (!isNowPage(window.location.pathname) && !hasEverHadXfinityServices(response.services)) {
      window.location.pathname = NO_XFINITY_SERVICES;
    }

    if (isNowPage(window.location.pathname) && !hasEverHadNowServices(response.services)) {
      window.location.pathname = NO_NOW_SERVICES;
    }

    return dispatch(dispatchGetAccountSuccess(response));
  } catch (error) {
    dispatch(dispatchGetAccountFailure(error));
    throw error;
  }
};

export const getConvoyIndicators = () => async (dispatch, getState) => {
  const {
    account: {
      account: { fullAccountNumber },
    } = {},
  } = getState();
  if (flagEnabled('txnDataTokenizationEnabled') || flagEnabled('txnDataPaymentEnabled')) {
    try {
      const response = await dispatch(dispatchGetConvoyIndicators(fullAccountNumber)).payload;
      return dispatch(dispatchGetConvoyIndicatorsSuccess(response));
    } catch (error) {
      // do nothing
      return null;
    }
  }
  return null;
};
