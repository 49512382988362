import apiClient from '../../middleware/apiClient';
import { BILL, DELINQUENCY, ECOBILL } from '../../helpers/apis';
import { toggleEcobillValue } from '../../helpers/bill';
import { encryptAutopay } from '../../helpers/payments';

import { enableAutopay } from '../autopay';
import { addInstrument, getPaymentInstruments } from '../instruments';
import { dispatchSendTransaction } from '../adobe';
import { acceptEcobillContract } from '../contracts';
import { sendChannelTracking } from '../channelTracking';

import { formatBillingAddress } from '../../helpers/address';
import { getErrorFromResponse } from '../../helpers/errors';

import {
  autopaySignupFromForm as crsAutopaySignup,
} from '../crs';
import { flagEnabled } from '../../helpers/featureFlags';

import {
  ECOBILL_ENROLL_FAILED,
  ECOBILL_UNENROLL_FAILED,
  ECOBILL_ENROLL_COMPLETE,
  ECOBILL_UNENROLL_COMPLETE,
} from '../../helpers/channelTracking';

import {
  GET_BILL,
  REFRESH_BILL,
  GET_BILL_SUCCESS,
  GET_BILL_FAILURE,
  GET_DELINQUENCY,
  GET_DELINQUENCY_SUCCESS,
  TOGGLE_ECOBILL,
  TOGGLE_ECOBILL_SUCCESS,
  TOGGLE_ECOBILL_FAILURE,
  SUBMIT_AUTOPAY_SIGNUP,
  SUBMIT_AUTOPAY_SIGNUP_SUCCESS,
  SUBMIT_AUTOPAY_SIGNUP_FAILURE,
} from './types';

export {
  GET_BILL,
  REFRESH_BILL,
  GET_BILL_SUCCESS,
  GET_BILL_FAILURE,
  GET_DELINQUENCY_SUCCESS,
  TOGGLE_ECOBILL,
  TOGGLE_ECOBILL_SUCCESS,
  TOGGLE_ECOBILL_FAILURE,
  SUBMIT_AUTOPAY_SIGNUP,
  SUBMIT_AUTOPAY_SIGNUP_SUCCESS,
  SUBMIT_AUTOPAY_SIGNUP_FAILURE,
};

function dispatchGetBill() {
  return {
    type: GET_BILL,
    payload: apiClient.fetch(BILL),
  };
}

function dispatchRefreshBill() {
  return {
    type: REFRESH_BILL,
    payload: apiClient.fetch(BILL),
  };
}

function dispatchGetDelinquency() {
  return {
    type: GET_DELINQUENCY,
    payload: apiClient.fetch(DELINQUENCY),
  };
}

function dispatchGetBillSuccess(bill) {
  return {
    type: GET_BILL_SUCCESS,
    payload: bill,
  };
}

function dispatchGetBillFailure(error) {
  return {
    type: GET_BILL_FAILURE,
    payload: error,
  };
}

function dispatchGetDelinquencySuccess(delinquency) {
  return {
    type: GET_DELINQUENCY_SUCCESS,
    payload: delinquency,
  };
}

export const getBill = () => async (dispatch, getState) => {
  if (getState().bill.cached) {
    return null;
  }

  try {
    const response = await dispatch(dispatchGetBill()).payload;
    return dispatch(dispatchGetBillSuccess(response));
  } catch (error) {
    dispatch(dispatchGetBillFailure(error));
    throw error;
  }
};

export const getDelinquency = () => async (dispatch) => {
  if (flagEnabled('txnDataTokenizationEnabled') || flagEnabled('txnDataPaymentEnabled')) {
    try {
      const response = await dispatch(dispatchGetDelinquency()).payload;
      return dispatch(dispatchGetDelinquencySuccess(response));
    } catch (error) {
      // do nothing
      return null;
    }
  }
  return null;
};

export const refreshBill = () => async (dispatch) => {
  try {
    const response = await dispatch(dispatchRefreshBill()).payload;
    return dispatch(dispatchGetBillSuccess(response));
  } catch (error) {
    dispatch(dispatchGetBillFailure(error));
    throw error;
  }
};

function dispatchToggleEcobill(data) {
  return {
    type: TOGGLE_ECOBILL,
    payload: apiClient.fetch(ECOBILL, { method: 'PUT', body: JSON.stringify(data) }),
  };
}

function dispatchToggleEcobillSuccess(payment) {
  return {
    type: TOGGLE_ECOBILL_SUCCESS,
    payload: payment,
  };
}

function dispatchToggleEcobillFailure(error) {
  return {
    type: TOGGLE_ECOBILL_FAILURE,
    payload: error,
  };
}

export const toggleEcobill = () => async (dispatch, getState) => {
  const {
    bill: { bill: { ecoBillDeliveryMethod: { deliveryMethod } } },
  } = getState();

  const payload = {
    deliveryMethod: toggleEcobillValue(deliveryMethod),
  };
  const enrolling = payload.deliveryMethod === 'ELECTRONIC';
  try {
    const response = await dispatch(dispatchToggleEcobill(payload)).payload;
    if (enrolling) {
      dispatch(dispatchSendTransaction({
        action: 'Save',
        transaction: 'billing:ecobill:signup',
      }));
      dispatch(sendChannelTracking({ id: ECOBILL_ENROLL_COMPLETE, interactionType: 'BILLING_PAYMENTS' }));
    } else {
      dispatch(dispatchSendTransaction({
        action: 'Submit',
        transaction: 'billing:paper bill:signup',
      }));
      dispatch(sendChannelTracking({ id: ECOBILL_UNENROLL_COMPLETE, interactionType: 'BILLING_PAYMENTS' }));
    }

    return dispatch(dispatchToggleEcobillSuccess(response));
  } catch (error) {
    dispatch(dispatchToggleEcobillFailure(error));
    dispatch(sendChannelTracking({
      id: enrolling ? ECOBILL_ENROLL_FAILED : ECOBILL_UNENROLL_FAILED,
      error,
      interactionType: 'BILLING_PAYMENTS',
    }));
    throw error;
  }
};

function dispatchAutopaySignup(payload) {
  return {
    type: SUBMIT_AUTOPAY_SIGNUP,
    payload,
  };
}

function dispatchAutopaySignupSuccess() {
  return {
    type: SUBMIT_AUTOPAY_SIGNUP_SUCCESS,
  };
}

function dispatchAutopaySignupFailure(error) {
  return {
    type: SUBMIT_AUTOPAY_SIGNUP_FAILURE,
    error,
  };
}

// TODO: rename consent-related stuff from autopaySignup to paymentSetup, to better match URLs.
export const submitAutopaySignup = values => async (dispatch, getState) => {
  const {
    account: { account },
    auth: { crsId },
    instruments: { instruments: { jwkMap: { makePayment } } },
  } = getState();

  const { paymentMethodOption, ecobill, autopay } = values;
  // Bank these so they don't hold up other parallel tasks.
  const ecobillPromises = [];
  const finishEcobill = async () => {
    if (ecobill) {
      if (ecobillPromises.length) {
        await Promise.all(ecobillPromises);
      }
    }
  };

  if (crsId && flagEnabled('consent.enabled')) {
    await crsAutopaySignup(values)(dispatch, getState);
    return;
  }

  if (ecobill) {
    ecobillPromises.push(dispatch(acceptEcobillContract()));
    ecobillPromises.push(dispatch(toggleEcobill()));
    // Fast-exit if the user hasn't entered a payment method.
    if (
      (paymentMethodOption === 'Bank' && !values.bankNumber)
      || (paymentMethodOption === 'PaymentCard' && !values.cardNumber)
    ) {
      await finishEcobill();
      return;
    }
  }

  try {
    // No PCI in the Redux state!
    dispatch(dispatchAutopaySignup({ ...values, cardNumber: null, bankAccountNumber: null }));
    const isNewInstrument = paymentMethodOption === 'Bank' || paymentMethodOption === 'PaymentCard';
    let autopayResponse;
    if (autopay) {
      autopayResponse = await dispatch(enableAutopay({
        isUpdate: false,
        billingAddress: formatBillingAddress(account, values),
        ...await (async () => {
          if (isNewInstrument) {
            return encryptAutopay(paymentMethodOption, values, makePayment);
          }
          if (paymentMethodOption === 'Apple Pay') {
            return { paymentMethodOption };
          }
          return { token: paymentMethodOption };
        })(),
      }));
    }
    if (!autopay && isNewInstrument) {
      await dispatch(addInstrument(paymentMethodOption, values));
      await dispatch(getPaymentInstruments());
    }
    if (autopay && isNewInstrument) {
      dispatch(dispatchSendTransaction({
        name: 'self service transaction',
        action: 'Add',
        transaction: 'billing:ewallet:signup',
      }));
      await dispatch(getPaymentInstruments());
    }
    // Make sure ecobill calls finish too before considering this complete.
    await finishEcobill();
    // success!
    dispatch(dispatchAutopaySignupSuccess(autopayResponse));
    return;
  } catch (error) {
    dispatch(dispatchAutopaySignupFailure(getErrorFromResponse(error)));
    throw error;
  }
};
